<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-item
      class="hydrated link"
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-label @click="openPopover(item, $event)">
        <h2>
          {{ item.name }} <ion-note>Class {{ item.standard.name }}</ion-note>
        </h2>
        <ion-note>{{ item.subject_group.name }}</ion-note>
      </ion-label>
    </ion-item>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No subjects found</p>
    </ion-list-header>
  </ion-list>

  <ion-popover
    :is-open="popoverState"
    :event="popoverEvent"
    @didDismiss="setPopoverState(false)"
  >
    <base-popover
      @closePopover="setPopoverState(false)"
      :title="`
        ${selectedResource.name} (Class ${selectedResource.standard.name})`"
    >
      <ion-item
        button
        :router-link="{
          name: 'subject.student',
          params: {
            subject: selectedResource.id,
            title: `${selectedResource.name} - Class ${selectedResource.standard.name}`,
          },
        }"
        @click="setPopoverState(false)"
        >Students</ion-item
      >
      <ion-item
        button
        :router-link="{
          name: 'subject.chapter',
          params: {
            subject: selectedResource.id,
            title: `${selectedResource.name} - Class ${selectedResource.standard.name}`,
          },
        }"
        @click="setPopoverState(false)"
        >Chapters</ion-item
      >
    </base-popover>
  </ion-popover>
</template>
<script>
import BasePopover from "../../../Base/BasePopover.vue";
import {
  IonList,
  IonListHeader,
  IonLabel,
  IonNote,
  IonItem,
  IonPopover,
} from "@ionic/vue";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonNote,
    IonItem,
    IonPopover,
    BasePopover,
  },
  data() {
    return {
      popoverState: false,
      popoverEvent: null,
      selectedResource: null,
    };
  },
  methods: {
    openPopover(resource, ev) {
      this.popoverEvent = ev;
      this.setSelectedResource(resource);
      this.setPopoverState(true);
    },

    setPopoverState(state) {
      this.popoverState = state;
    },

    setSelectedResource(resource) {
      this.selectedResource = resource;
    },

    setUpdateModalState(state) {
      this.updateModalState = state;
    },
  },
};
</script>
